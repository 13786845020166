<template>
  <div
      class="flex flex-col text-a-neutral bg-a-main-gray relative font-sans tracking-wide"
  >
    <FundraisingNav class="hidden md:flex"/>
    <FundraisingNavMobile class="flex md:hidden"/>
    <MainSideMenuMobile class="flex md:hidden"/>
    <div class="flex flex-col px-4 md:px-8 lg:px-16">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script setup lang="ts">
</script>

<style></style>
